import React, { Component } from "react";
import styles from "./NoErrors.module.css";

class NoErrors extends Component {
    render() {
        return (
                <h1>Congratulations, your typing is perfect!</h1>
        );
      }
}

export default NoErrors;
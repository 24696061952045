import React, { Component } from "react";

class Exercise extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <h3>Training exercise</h3>
        <p>1 of ?</p>
        <p>
          Main text entry box - but populated with words containing the target
          letters
        </p>
      </React.Fragment>
    );
  }
}

export default Exercise;
